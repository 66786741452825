<template>

    <div>
        <div class="row card-group-row" v-if="courses.count && courses.count > 0">

            <div class="col-sm-6 col-md-4 card-group-row__col" v-for="course in courses.data"
                 :key="'course' + course.id">

                <CardCourse
                    :course="course"
                />
            </div>
        </div>

    </div>
</template>

<script>

import CardCourse from "@/views/mentoring/list/card-course.vue";
import {getUrl} from "@/components/composables/functions";
import {setSessions} from "@/components/composables/setSessions";

export default {
    components: {
        CardCourse
    },
    data() {
        return {}
    },

    methods: {

        loadList() {
            if (!localStorage.getItem('Mentoring')) setSessions('Mentoring');
            const url = getUrl('Mentoring');
            this.$store.dispatch('api/getApi', url);
        },
    },

    mounted() {
        // chamada de busca de dados na store
        this.loadList();
    },

    computed: {
        courses: {
            get() {
                return this.$store.state.api.listAll ?? {};
            },
        },
    }
}
</script>

<style scoped>

.card-img-top.js-image {
    display: block;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
}
</style>
