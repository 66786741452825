<template>
    <div
        class="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card"
        data-toggle="popover" data-trigger="click" data-original-title="" title=""
        data-domfactory-upgraded="overlay">

        <b-skeleton-img
            v-if="loading"
            style="aspect-ratio: 1 / 1;"
        />
        <div class="card-img-top js-image" data-position="center" data-domfactory-upgraded="image"
             v-show="!loading">
            <img :src="course.logoUrl"
                 class="mentoring-image"
                 @load="handleImageLoad"
                 alt="course">
        </div>

        <div class="card-body">
            <div class="card-title mb-2">
                {{ course.title }}
            </div>


            <div class="row justify-content-between">
                <div class="col-auto d-flex align-items-center" v-if="course.mentees_count > 0">
                    <i class="fa fa-users icon-16pt text-50 mr-4pt"></i>
                    <p class="flex text-50 lh-1 mb-0"><small>
                        <b>{{ course.mentees_count }} {{ course.mentees_count > 1 ? 'mentorados' : 'mentorado' }}</b>
                    </small></p>
                </div>
                <div class="col-auto d-flex align-items-center" v-else>
                    <p class="flex text-50 lh-1 mb-0"><small>
                        <b>Nenhum mentorado</b>
                    </small></p>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <p class="flex text-50 lh-1 mb-0"><small>
                        {{
                            parseFloat(course.price || 0).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        }}
                    </small>
                    </p>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <Actions :id="course.id" :types="types" url="mentorias/" api="mentorings/"
                     :data="course"/>
        </div>
    </div>
</template>

<script>

import Actions from "@/components/base/actions.vue";

export default {
    components: {Actions},
    props: {
        course: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            types: ['edit', 'details', 'copy'],
        }
    },

    methods: {
        handleImageLoad() {
            this.loading = false;
        }
    }
}

</script>
